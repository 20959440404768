
import { defineComponent } from '@nuxtjs/composition-api'
import { seoHead, getIndirectClientHeaders } from '~/assets/seo'

export default defineComponent({
  name: 'PagesHome',
  nuxtI18n: {
    paths: {
      nl: '/',
      en: '/',
    },
  },
  async asyncData({ redirect, req, route, store, error }) {
    const [, globalSeo, page] = await Promise.all([
      store.dispatch('global/fetchNavigation'),
      store.dispatch('global/fetchSeo'),
      store.dispatch('page/fetchUri', { uri: route.fullPath, push: true, options: { headers: getIndirectClientHeaders({ req }) } }),
    ])

    if (!page) {
      return error({ statusCode: 404, message: 'Page not found' })
    }

    if (page._redirect_) {
      return redirect(page._redirect_.type, page._redirect_.to)
    }

    store.commit('tix/setTixEnv', 'tix') // use "normal" tix env

    return {
      globalSeo,
      page,
    }
  },
  head() {
    return seoHead(this.page, this.globalSeo)
  },
})
